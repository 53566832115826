import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet presenta un capell viscós en temps humit de 4 a 8 cm de diàmetre, primer globós i carnós i després estès, de color roig al centre i clar als marges. Les làmines són de color groc a groc argila i amb el temps tornen quasi negres per la caiguda de les espores. Aquestes són el·lipsoidals, terroses en massa, de 6-8 x 3-5 micres. El peu és cilíndric, corbat, fusiforme, buit, de color groc a la part superior i marró a la resta i amb una cortina fugaç groguenca als exemplars joves.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      